import http from "@/common/js/http";

//问题列表
export const getQuestionListApi = async (form) => {
    return await http(`/galaxyLesson/questionList`, form, 'POST');
};

//新增问题
export const addQuestionApi = async (form) => {
    return await http(`/galaxyLesson/addQuestion`, form, 'POST');
};

//编辑问题
export const updateQuestionApi = async (form) => {
    return await http(`/galaxyLesson/updateQuestion`, form, 'POST');
};

//课件列表
export const getLessonListApi = async (form) => {
    return await http(`/galaxyLesson/lessonList`, form, 'POST');
};

//添加课件
export const addLessonApi = async (form) => {
    return await http(`/galaxyLesson/addLesson`, form, 'POST');
};

//编辑课件
export const updateLessonApi = async (form) => {
    return await http(`/galaxyLesson/updateLesson`, form, 'POST');
};

//培训列表
export const trainingListApi = async (form) => {
    return await http(`/galaxyLesson/trainingList`, form, 'POST');
};

//添加培训
export const addTrainingApi = async (form) => {
    return await http(`/galaxyLesson/addTraining`, form, 'POST');
};

//编辑培训
export const updateTrainingApi = async (form) => {
    return await http(`/galaxyLesson/updateTraining`, form, 'POST');
};

//更新上下线状态
export const updateTrainingStatusApi = async (form) => {
    return await http(`/galaxyLesson/updateTrainingStatus`, form, 'POST');
};

//通过培训id查询司机培训详情
export const getDriverTrainingInfoByTrainingIdApi = async (form) => {
    return await http(`/galaxyLesson/getDriverTrainingInfoByTrainingId`, form, 'POST');
};

/**
 * 通过培训id查询司机培训详情 导出
 */
export const exportDriverTrainingInfoByTrainingIdApi = async (data) => {
    return await http(`/galaxyLesson/exportDriverTrainingInfoByTrainingId`, data, 'POST', false, 'blob');
};

/**
 * 批量添加问题
 */
export const batchAddQuestionApi = async (data) => {
    return await http(`/galaxyLesson/batchAddQuestion`, data, 'POST');
};


/**
 * 签名列表
 */
export const driverSafeSignatureListApi = async (data) => {
    return await http(`/galaxyDriverApproveApi/driverSafeSignatureList`, data, 'POST');
};

/**
 * 删除签名
 */
export const deleteDriverSafeSignatureApi = async (data) => {
    return await http(`/galaxyDriverApproveApi/deleteDriverSafeSignature`, data, 'POST');
};
