export const messageTypeOptions = [
    {
        label: '紧急通知',
        value: 0,
        bg: require('@/www/assets/images/message/bg_jjtz.png')
    },
    {
        label: '友情提醒',
        value: 1,
        bg: require('@/www/assets/images/message/bg_yqts.png')
    },
    {
        label: '平台规则',
        value: 2,
        bg: require('@/www/assets/images/message/bg_ptgz.png')
    },
    {
        label: '其他消息',
        value: 3,
        bg: require('@/www/assets/images/message/bg_tz.png')
    }
]

export const messageDisplayModeOptions = [
    // {
    //     label: '消息列表',
    //     value: 0
    // },
    {
        label: '弹窗',
        value: 1
    },
]

export const messageChannelOptions = [
    {
        label: '司机端',
        value: 1
    }
]