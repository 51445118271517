<script>
import {addLessonApi, updateLessonApi} from "@/www/urls/driverLearning";
import tinymceVue from "@/www/components/tinymce-vue/index.vue";
import ImageUpload from "@/components/imageUpload/index.vue";

export const coursewareTypeOptions = [
    {
        label: '岗前宣导',
        value: '5'
    },
    {
        label: '交规宣导',
        value: '1'
    },
    {
        label: '行车安全',
        value: '2'
    },
    {
        label: '政策相关',
        value: '3'
    },
    {
        label: '其他',
        value: '4'
    }
]

const initForm = {
    lessonTitle: coursewareTypeOptions[0].label,
    lessonInfo: undefined,
    lessonName: '',
    lessonCoverUrl: []
}
export default {
    name: "edit-dialog",
    data() {
        return {
            visible: false,
            loading: false,
            coursewareTypeOptions,
            form: {
                ...initForm
            },
            rules: {
                lessonCoverUrl: {required: true, message: '请上传课件封面', trigger: 'change'},
                lessonName: {required: true, message: '请输入课件主题', trigger: 'change'},
                lessonInfo: {required: true, message: '请输入课件内容', trigger: 'change'}
            }
        }
    },
    components: {
        ImageUpload,
        tinymceVue
    },
    methods: {
        open(record = {}) {
            this.visible = true;
            this.form = {
                ...initForm,
                ...record,
                lessonCoverUrl: [{
                    imageUrl: record.lessonCoverUrl,
                    showImage: true
                }]
            }
        },
        async handleSave() {
            try {
                await this.$refs['form'].validate();
                this.loading = true;
                const fn = this.form.id ? updateLessonApi : addLessonApi;
                const ret = await fn({
                    ...this.form,
                    lessonCoverUrl: this.form.lessonCoverUrl[0].imageUrl,
                    lessonInfo:this.form.lessonInfo.replace(/<video/g,'<video playsinline')
                })
                if (ret.success && ret.data) {
                    this.$message.success('保存成功');
                    this.visible = false;
                    this.$emit('success', this.form)
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<template>
    <el-dialog :visible.sync="visible" :append-to-body="false" :title="(form.id?'编辑':'新建')+'课件'" width="1000px"
               top="30px">
        <div class="edit-dialog">
            <el-form ref="form" label-width="100px" :model="form" :rules="rules" hide-required-asterisk>
                <el-form-item label="课件类型：" prop="lessonTitle">
                    <el-radio-group v-model="form.lessonTitle">
                        <el-radio :label="vm.label" v-for="vm in coursewareTypeOptions" :key="vm.label">
                            {{ vm.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="课件主题：" prop="lessonName">
                    <el-input style="width: 850px" placeholder="请输入题目，最多可填入50个中文字" :maxlength="20"
                              v-model="form.lessonName">
                        <span slot="suffix" class="color-gary">{{ form.lessonName.length }}/20</span>
                    </el-input>
                </el-form-item>
                <el-form-item label="课件封面：" prop="lessonCoverUrl">
                    <div style="display:flex;align-items: center">
                        <ImageUpload :images="form.lessonCoverUrl"
                                     :constraint="{width:80,height:80,size:1024*1024}"
                                     @update:imageUploadCompleted="form.lessonCoverUrl=$event"></ImageUpload>
                        <span class="ml10 color-gary">图片要求：尺寸80×80px，1MB以内</span>
                    </div>
                </el-form-item>
                <el-form-item label="课件内容：" prop="lessonInfo">
                    <tinymce-vue :width="850" show-video v-model="form.lessonInfo"></tinymce-vue>
                </el-form-item>
            </el-form>
            <!--            <div class="preview-box">-->
            <!--                <div class="title">{{ form.lessonName }}</div>-->
            <!--                <div v-html="form.lessonInfo" class="content rich-text-to-h5"></div>-->
            <!--            </div>-->
        </div>
        <footer slot="footer">
            <el-button type="primary" plain @click="visible=false" :loading="loading">取消</el-button>
            <el-button type="primary" @click="handleSave" :loading="loading">保存</el-button>
        </footer>
    </el-dialog>
</template>

<style>
.tox-tinymce-aux {
    z-index: 10000 !important;
}
</style>
<style scoped lang="scss">
.edit-dialog {
    display: flex;

    .preview-box {
        width: 300px;
        height: 565px;
        border: 1px solid rgba(220, 223, 230, 1);
        margin-left: 40px;
        margin-top: 63px;

        .title {
            height: 60px;
            background: #F9F9F9;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            color: #333333;
            font-weight: 500;
        }

        .content {
            padding: 16px 10px;
            overflow: scroll;
            height: 473px;
        }
    }

    &::v-deep {
        .el-form-item .el-form-item {
            margin-bottom: 22px;
        }

        .el-checkbox:not(.is-checked) {
            .el-checkbox__label {
                font-weight: normal;
                color: #999;
            }
        }

        .el-checkbox.is-checked {
            .el-checkbox__inner {
                background-color: #44B214;
                border-color: #44B214;
            }

            .el-checkbox__label {
                font-weight: normal;
                color: #44B214;
            }
        }
    }
}
</style>
